import React from 'react'
import { rgba } from 'polished'
import { Box, BoxProps } from '../../../atoms'
import styled, { css } from 'styled-components'

export const BoxWithDot: React.FC<BoxProps & { as?: React.ElementType; dotColor: string; ordered: boolean }> = styled(
  Box
)<BoxProps & { as?: React.ElementType; dotColor: string; ordered: boolean }>`
  ${({ ordered }) =>
    !ordered &&
    css`
      padding-left: ${({ theme }) => theme.space[4]}px;
      &:before {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMgOEw3LjIyMjIyIDEyTDEzIDQiIHN0cm9rZT0iIzcxMDFGRiIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
        width: 16px;
        height: 16px;
        padding: 4px;
        border-radius: 100%;
        position: absolute;
        background-color: ${({ theme }) => rgba(theme.colors.purpleBlue, 0.2)};
        top: 0;
        left: -24px;
      }
    `}
`

import React, { PropsWithChildren, useMemo } from 'react'
// Types
import { SectionBase, SectionResourceForm } from '../../../types/sections'
// Components
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Box, Flex, Heading, Image, Text } from '../../../atoms'
import { ContentMd, HubspotForm } from '../../commons'
import { BoxWithDot } from './ResourceForm.styled'

export interface ResourceFormProps extends SectionBase {
  data: SectionResourceForm
}

const ResourceForm: React.FC<ResourceFormProps> = ({ data }) => {
  const {
    id,
    sectionId,
    resource: { title, cover, description, content, hubspotForm },
  } = data

  const renderers = useMemo(() => {
    return {
      li: (props: PropsWithChildren<any>) => {
        return (
          <BoxWithDot as={'li'} position={'relative'} my={4} dotColor={'purpleBlue'} ordered={props.ordered}>
            <Text as={'span'} fontSize={2} lineHeight={1.5} {...props} color={'dark'} />
          </BoxWithDot>
        )
      },
    }
  }, [])

  return (
    <Box as={'section'} id={sectionId || id} className={'p-section'} my={[80, 80, 120]}>
      <Grid>
        <Row>
          <Col xs={12} md={7}>
            <Flex mb={[7, 0]} pr={[0, 0, 6]} flexWrap={['wrap', 'nowrap']}>
              {cover && (
                <Box flex={['0 0 100%', '0 0 180px']} mr={[0, 36]} mb={[6, 0]}>
                  <Image draggable={false} alt={cover.alt || title} image={cover.gatsbyImageData} />
                </Box>
              )}
              <Box flex={['0 0 100%', '1']}>
                <Heading as={'h1'} fontSize={['28px', '28px', '28px']} lineHeight={['34px', '34px', '34px']}>
                  {title}
                </Heading>
                {!!description && (
                  <Box mt={5}>
                    <Text fontSize={2} whiteSpace={'pre-line'}>
                      {description}
                    </Text>
                  </Box>
                )}
              </Box>
            </Flex>
            {!!content && (
              <Box mt={[8, 9, 10]} pr={[0, 0, 6]}>
                <ContentMd content={content} renderers={renderers} />
              </Box>
            )}
          </Col>
          <Col xs={12} md={5}>
            {!!hubspotForm && (
              <Box mx={'-10px'} mt={[8, 0]}>
                <HubspotForm region={hubspotForm.region} portalId={hubspotForm.portalId} formId={hubspotForm.formId} />
              </Box>
            )}
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

export default ResourceForm
